/** @format */

import React from "react";
import {Heading, Link, Text, VStack} from "@chakra-ui/react";
import {PATHS} from "../utils/constants";
import SEO from "../components/seo";

const Thanks = () => {
  return (
    <VStack mt={8} height="100vh" justify="center" alignItems="center">
      <Heading mt={10}>Thank You 😘 </Heading>
      <Text>Message recieved. We will get back as soon as possible </Text>
      <Link
        href={PATHS.HOME}
        variant="contact"
        _active={{textDecoration: "none"}}
        _hover={{textDecoration: "none"}}
        _visited={{textDecoration: "none"}}
      >
        Home
      </Link>
    </VStack>
  );
};

export default Thanks;

export const Head = () => <SEO title="- Your trusted IT consult - Thank You" />;
